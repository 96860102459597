/* App.css */

:root {
  --night: #0c090dff;
  --amaranth: #e01a4fff;
  --tomato: #f15946ff;
  --saffron: #f9c22eff;
  --moonstone: #53b3cbff;
  --deftblue: #3D405B;
  --charcoal: #404E5C;
  --mintcream: #F4FFFD;
  --onyx: #424242;
  --raisinblack: #272838;
  --eerieblack: #1B2021;
  --emerald: #2DD881;
  --keppel: #4EA699;
  --redcrayola: #EE4266;
  --verdigris: #3CBBB1;
  --carrotorange: #EA9010;
  --aureolin: #F7E733;
  --mimipink: #F2CEE6;
  --plum: #F9B9F2;
  --bluewhite: #F2F8FC;
  --jet: #333333;
  --lime: #CEFF1A;
  --savoyblue: #5158BB;
  --princetonorange: #FE9920;
}

html {
  -webkit-tap-highlight-color:transparent;
}

body {
  background-color: var(--eerieblack);
}

h1 {
  margin: 0;
}

.unselectable-text {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.centered-text {
  text-align: center;
}

.vertically-centered-text {
  display: flex;
  margin: auto;
  align-items:center;
  justify-content:center;
  height: 100%;
}

.horizontal-row {
  display: flex;
  flex-direction: row;
}

.horizontal-row * {
  margin: 2px;
}

.full-width {
  width: 100%;
}

.mute-icon {
  width: 20px;
  height: 20px;
  padding: 5px;
  margin: 0;
}

.form-switch {
  padding: 0;
  margin: 0;
}

.note-label-container {
  display: flex;
  padding-bottom: 3px;
  padding-left: 1px;
  width: 90%;
  height: 95%;
}

.note-label-octave { 
  position: relative; 
  bottom: -2px;
  left: -1px;
  font-size: 0.35rem; 
  align-self: flex-end;
}

.note-label-name {
  font-size: 0.5rem;
  bottom: 2px;
  align-self: flex-end;
  padding-left: 1px;
}

.note-label-info {
  font-size: 0.4rem;
  bottom: 2px;
  align-self: flex-end;
  padding-left: 1px;
  text-align: center;
  color: var(--charcoal);
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

.ReactPiano__NoteLabelContainer {
  height: 100%;
  margin-top: auto;
}

.ReactPiano__Key {
  user-select: none;
}

.ReactPiano__Key--natural {
  background: var(--mintcream) !important;
}
 
.ReactPiano__Key--accidental {
  background: var(--night) !important;
}

.ReactPiano__Key--active {
  background: var(--emerald) !important;
}

.dot-circle {
  r: 13;
  fill: var(--jet);
  stroke: var(--emerald);
}

.dot-text {
  font-size: 10px;
  font-weight: bold;
  fill: white;
  font-family: "SF Pro Text","SF Pro Icons","Apple WebExp Icons Custom","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.scale-selection-dropdown > button {
  background-color: #004E89;
}

.chord-root-note {
  font-size: 12px;
  font-weight: bold;
}

.chord-info {
  position: relative; 
  bottom: -3px;
  font-size: 0.6rem; 
  align-self: flex-end;
  font-style: italic;
}

.chord-bass-note {
  margin-left: 4px;
}

#App {
  width: 100%;
  height: 100%;
}

#header {
  background-color: var(--redcrayola);
  width: 100%;
  height: 39px;
  border-bottom: 1px solid var(--charcoal);
}

#header ul {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin-left: 0px;
  width: calc(100% - 12px);
}

#header ul li {
  margin-left: 7px;
}

#header ul li:last-child {
  float: right;
}

#header ul li {
  display: block;
  list-style: none;
  display: inline-block;
}

#explanation-banner {
  background-color: var(--bluewhite);
  color: var(--jet);
  font-size: 13px;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: -.005em;
  font-family: "SF Pro Text","SF Pro Icons","Apple WebExp Icons Custom","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
  margin: 0;
  padding-left: 50px;
  padding-right: 50px;
}

#mute-button {
  font-size: 10px;
  margin-top: 6px;
  margin-left: 8px;
}

#mute-button > div {
  padding: 0;
  margin: 0;
}

#help-message-box {
  height: 100%;
  width: 100%;
  z-index: 100 !important;
  background-color: white;
}

#help-message-box-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

#keyboard-container {
  width: 94%;
  height: 100px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 30px;
}

#guitar-container {
  margin: 0px;
}

#clear-notes-button {
  width: 100%;
  height: 50px;
  margin-top: 6px;
  background-color: var(--deftblue);
  border-color: var(--redcrayola);
}

#scale-selection-card {
  width:290px;
  height: 180px;
}

#show-scale-button {
  width: 240px;
  background-color: var(--emerald);
  margin: 5px;
}

#scale-selection-inputs {
  margin-top: 6px;
}

#scale-selection-inputs > div {
  display: inline-block;
  margin-left: 6px;
}

#detected-chords-card-container {
  display: inline-block;
  padding-left: 6px;
  width: 180px;
  height: 180px;
}

#detected-chords-card .card-body {
  padding: 0;
  overflow: scroll;
}

#detected-chords-card .list-group {
  border-top: 0;
  border-bottom: 0;
}

#detected-chords-card .list-group-item {
  border-radius: 0;
  height: fit-content;
  font-size: 0.7em;
}

#detected-scales-card-container {
  display: inline-block;
  padding-left: 6px;
  width: calc(100% - 290px - 180px);
  height: 180px;
}

#detected-scales-card-container ul {
  list-style: none;
  padding: 4px;
  display: flex;
  flex-flow: wrap column;
  max-height: 146px;
  margin: 0;
  display: inline-block;
}

#detected-scales-card-container ul > li {
  background-color: var(--eerieblack);
  color: var(--moonstone);
  font-size: 0.6em;
  font-weight: bold;
  border: 1px solid var(--carrotorange);
  border-radius: 10px;
  margin-right: 4px;
  margin-top: 2px;
  padding: 4px 6px 4px 6px;
  text-align: center;
  display: inline-block;
}

#detected-scales-card-container > div {
  height: 100%;
}

#detected-scales-card .card-body {
  padding: 0px;
  overflow: scroll;
}

#detected-chords-card-container > div {
  height: 100%;
  width: 100%;
}

#detected-chords-card .list-group-item {
  border-left: 0;
  border-right: 0;
}

#detected-chords-card .list-group-item:first-child {
  border-top: 0;
}

#detected-chords-card .list-group-item:last-child {
  border-bottom: 0;
}

#bottom-section {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}

@media screen and (max-width: 1100px) {

  #bottom-section > div {
    width: 100%;
    padding: 0px;
  }

  #bottom-section > div .card {
    width: 100%;
  }

  #bottom-section > div:not(:first-child) {
    margin-top: 6px;
  }

  #show-scale-button {
    margin-bottom: 6px;
  }

  #scale-selection-card {
    height: fit-content;
    padding-right: 13px;
  }

  #scale-selection-inputs {
    width: 100%;
  }

  .scale-selection-dropdown {
    width: calc(50% - 6px);
  }

  .scale-selection-dropdown > button {
    width: 100%;
    margin-top: 2px;
  }

  #show-scale-button {
    width: 100%;
  }

  #explanation-banner {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #detected-scales-card-container {
    height: fit-content;
  }

  #detected-scales-card .card-body > div {
    min-height: 137px;
  }

  #detected-scales-card .card-body {
    padding: 6px;
    padding-bottom: 12px;
  }

  #detected-scales-card ul > li {
    font-size: 0.8em;
  }
}
